import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
//Explore more Monday React Components here: https://style.monday.com/



import AttentionBox from "monday-ui-react-core/dist/AttentionBox.js";

import {default as GeneralInfo} from "./components/GeneralInfoView";
import {default as AddGroup} from "./components/AddGroupView.js";
import {default as AddUsers} from "./components/AddUsersView.js";
import {default as AddAutomatizations} from "./components/AddAutomatizations/AddAutomatizationsView.js";
import {default as Calendar} from "./components/Calendar/CalendarView.js";
import {default as ConnectCalendar} from "./components/connect/ConnectView.js";

import {CONNECT_STEP, CREATECALENDAR_STEP, ADDGROUP_STEP, ADDUSER_STEP, ADDAUTOMATIZATION_STEP, SHOWCALENDAR_STEP} from "./settings.js";

// Usage of mondaySDK example, for more information visit here: https://developer.monday.com/apps/docs/introduction-to-the-sdk/
const monday = mondaySdk();

let calendarInfo = {};



function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}


const App = () => {
  const [context, setContext] = useState();
  const [step, setStep] = useState(CONNECT_STEP)

  useEffect(() => {
    // Notice this method notifies the monday platform that user gains a first value in an app.
    // Read more about it here: https://developer.monday.com/apps/docs/mondayexecute#value-created-for-user/
    monday.execute("valueCreatedForUser");

    // TODO: set up event listeners, Here`s an example, read more here: https://developer.monday.com/apps/docs/mondaylisten/
    monday.listen("context", (res) => {
      setContext(res.data);
    });

    monday.get("context").then(res => console.log(res))
  }, []);

  //Some example what you can do with context, read more here: https://developer.monday.com/apps/docs/mondayget#requesting-context-and-settings-data
  const attentionBoxText = `Hello, your user_id is: ${
    context ? context.user.id : "still loading"
  }.
  Let's start building your amazing app, which will change the world!`;
      /*<AttentionBox
        title="Hello Monday Apps!"
        text={attentionBoxText}
        type="success"
      />*/

  if (getCookie('calendarInfo')) {
    calendarInfo = JSON.parse(getCookie('calendarInfo'));
    setStep(SHOWCALENDAR_STEP);
  }

  return (
    <div className="App">
      {
        (step == CONNECT_STEP) &&
          <ConnectCalendar calendarInfo={calendarInfo} monday={monday} changeStep={setStep}/>
      }
      {
        (step == CREATECALENDAR_STEP) &&
          <GeneralInfo calendarInfo={calendarInfo} monday={monday} changeStep={setStep}/>
      }
      {
        (step == ADDGROUP_STEP) &&
          <AddGroup calendarInfo={calendarInfo} changeStep={setStep}/>      
      }
      {
        (step == ADDUSER_STEP) &&
          <AddUsers calendarInfo={calendarInfo} monday={monday} changeStep={setStep}/>
      }
      {
        (step == ADDAUTOMATIZATION_STEP) &&
          <AddAutomatizations monday={monday} changeStep={setStep}/>
      }
      {
        (step ==  SHOWCALENDAR_STEP) &&
          <Calendar calendarInfo={calendarInfo} monday={monday} changeStep={setStep}/> 
      }
    </div>
  );
};

export default App;
